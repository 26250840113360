import React from 'react'
import { AnalyticsProvider } from '../context/ga'
import Registration from '../components/Registration'

import { AreaProvider } from '../context/area'

const SignIn = () => {
  return (
    <AreaProvider>
      <AnalyticsProvider>
        <Registration gaTag={'pageScrn'} />
      </AnalyticsProvider>
    </AreaProvider>
  )
}

export default SignIn
